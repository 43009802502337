<template>
  <div>
    <el-form :inline="true">
      <!-- 搜索区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:200px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
      <!-- 信息审核状态 -->
      <el-form-item>
        <el-dropdown split-button type="primary" @command="getbystatus">
          {{ $t('yh.xxshzt') }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="3">{{
              $t('yh.quanbu')
            }}</el-dropdown-item>
            <el-dropdown-item command="0">{{ $t('mmb.dsh') }}</el-dropdown-item>
            <el-dropdown-item command="1">{{ $t('tongguo') }}</el-dropdown-item>
            <el-dropdown-item command="2">{{ $t('bohui') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
      <!-- 时间搜索 -->
      <el-form-item>
        <el-date-picker
          v-model="queryinfo.start"
          type="datetime"
          :placeholder="$t('qxz')"
          align="right"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryinfo.end"
          type="datetime"
          :placeholder="$t('qxz')"
          align="right"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-button icon="el-icon-search" circle @click="getlist"></el-button>
    </el-form>
    <!-- 列表区域 -->
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column prop="code" :label="$t('yh.sqdh')"></el-table-column>
      <el-table-column
        prop="recharge_num"
        :label="$t('yh.czsl')"
      ></el-table-column>
      <el-table-column
        prop="product_type"
        :label="$t('bizhong')"
      ></el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('yh.czsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column prop="status" :label="$t('yh.shzt')">
        <template slot-scope="{ row }">
          <span v-if="row.status === '0'">{{ $t('mmb.dsh') }}</span>
          <span v-else-if="row.status === '1'">{{ $t('tongguo') }}</span>
          <span v-else>{{ $t('bohui') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" :label="$t('beizhu')"></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-button
            @click.prevent="shenhe(row.id)"
            v-if="row.status === '0'"
            >{{ $t('yh.xxsh') }}</el-button
          ><span v-else> </span>
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 信息审核 -->
    <el-dialog
      :title="$t('yh.shenhe')"
      :visible="shenhevisible"
      :before-close="shenheclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <b>{{ $t('yh.czbm') }}：</b>{{ editform.code }}<br />
        <b>{{ $t('yh.czlxing') }}：</b>{{ editform.wallet_type }}<br />
        <b>充币数量：</b>{{ editform.recharge_num }}<br />
        <b>充币地址：</b>{{ editform.wallet_address }}<br />
        <b>汇率：</b>{{ editform.rate }}<br />
        <b>到账USDT：</b>{{ editform.total }}<br />
        <b>充值截图：</b>
        <el-image
          style="max-width: 150px; max-height: 150px"
          :src="editform.recharge_image"
          fit="cover"
        ></el-image>
        <br />
      </div>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button @click="shenheclose">{{ $t('quxiao') }}</el-button>
        <el-button v-preventClick type="primary" @click="shenhesubmit(1)">{{
          $t('tongguo')
        }}</el-button>
        <el-button v-preventClick type="danger" @click="shenhesubmit(2)">{{
          $t('bohui')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑弹窗区域 -->
    <el-dialog
      title="编辑"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="备注">
          <el-input v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">取消</el-button>
        <el-button type="primary" @click="editsubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 快捷时间
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 分页请求数据对象
      queryinfo: {
        query: '',
        start: '',
        end: '',
        page: 1,
        status: 3
      },
      infolist: [],
      total: 0,
      loading: true,
      editdialogvisible: false,
      status: '',
      editid: '',
      remark: '',
      shenhevisible: false,
      editform: {}
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/user/rechargelog', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.total = +data.data.count
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 打开审核弹窗
    async shenhe(id) {
      this.shenhevisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/user/editrecharge/id/' + id)
      if (data) {
        if (data.code === 200) {
          this.editform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 审核提交
    async shenhesubmit(content) {
      const { data } = await this.$http.post('/admin/user/editrecharge', {
        id: this.editid,
        action: 'sh',
        content: content
      })
      if (data) {
        if (data.code === 200) {
          this.getlist()
          this.$message.success(this.getlang(data.msg))
          this.shenheclose()
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      this.editdialogvisible = false
    },
    // 关闭审核弹窗
    shenheclose() {
      this.shenhevisible = false
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/user/editrecharge/id/' + id)
      if (data) {
        if (data.code === 200) {
          this.editform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 编辑提交
    async editsubmit() {
      const { data } = await this.$http.post('/admin/user/editrecharge', {
        id: this.editid,
        action: 'bz',
        content: this.remark
      })
      if (data) {
        if (data.code === 200) {
          this.getlist()
          this.remark = ''
          this.$message.success(this.getlang(data.msg))
          this.editclose()
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      this.editdialogvisible = false
    },
    // 关闭编辑弹窗
    editclose() {
      this.editdialogvisible = false
    },
    getbystatus(command) {
      this.queryinfo.status = command
      this.getlist()
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
